<template>
  <div class="synthesis-sheets container is-fluid">
    <page-header>
      <h1 class="title">Fiche affaire générale</h1>
      <span slot="actions">
        <b-select placeholder="Période" v-model="currentMonth">
          <option v-for="date in months" :value="date.value" :key="date.value">
            {{ date.name }}
          </option>
        </b-select>
      </span>
    </page-header>

    <span class="tme"
      >Dernière mise à jour le {{ latestRefresh | date("DD/MM/YYYY") }} à
      {{ latestRefresh | date("HH:mm") }}</span
    >
    <div class="sheet-synthesis panel has-background-white">
      <div class="filters has-background-info has-text-primary">
        <div class="filter-item">
          <many2one-field
            label="Projets"
            v-model="projects"
            :fetch="$Api.Project.fetchProjects"
            reference="name"
            entity="project"
            :columns="projectsColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            label="Modèles de fiche"
            v-model="sheetTemplates"
            :fetch="
              $Api.SheetTemplate.fetchSheetTemplatesForProjects.bind(
                this,
                projectsIds
              )
            "
            reference="name"
            entity="sheet-template"
            :columns="{ name: 'Modèle de fiche affaire' }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            label="Fiches affaire"
            v-model="sheets"
            :fetch="
              $Api.Sheet.fetchSheetsForProjectsAndSheetTemplates.bind(
                this,
                projectsIds,
                sheetTemplatesIds,
                milestoneNames,
                statusesIds,
                administrationStatusesIds,
                ownersIds
              )
            "
            reference="name"
            entity="sheet"
            :columns="{ name: 'Fiche affaire' }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            label="Statuts"
            v-model="statuses"
            :fetch="
              $Api.Sheet.fetchStatusesForProjectsAndSheetTemplates.bind(
                this,
                projectsIds,
                sheetTemplatesIds,
                sheetsIds,
                milestoneNames,
                administrationStatusesIds,
                ownersIds
              )
            "
            reference="name"
            entity="sheet"
            :columns="{ name: 'Fiche affaire' }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            label="Statuts de gestion"
            v-model="administrationStatuses"
            :fetch="
              $Api.Sheet.fetchAdministrationStatusesForProjectsAndSheetTemplates.bind(
                this,
                projectsIds,
                sheetTemplatesIds,
                sheetsIds,
                milestoneNames,
                statusesIds,
                ownersIds
              )
            "
            reference="name"
            entity="sheet"
            :columns="{ name: 'Fiche affaire' }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            label="Jalons"
            v-model="milestones"
            :fetch="
              $Api.Sheet.fetchMilestonesForProjectsAndSheetTemplates.bind(
                this,
                projectsIds,
                sheetTemplatesIds,
                sheetsIds,
                statusesIds,
                administrationStatusesIds,
                ownersIds
              )
            "
            reference="milestone"
            entity="sheet"
            :columns="{ milestone: 'Jalon' }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            label="Responsables"
            v-model="owners"
            :fetch="
              $Api.Sheet.fetchSheetOwnersForProjectsAndSheetTemplates.bind(
                this,
                projectsIds,
                sheetTemplatesIds,
                sheetsIds,
                milestoneNames,
                statusesIds,
                administrationStatusesIds
              )
            "
            reference="name"
            entity="sheet"
            :columns="{ name: 'Nom', mail: 'Email' }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="valid-button">
          <button class="button is-info is-light" @click="fetchItems()">
            Calculer
          </button>
        </div>
      </div>

      <div class="panel-block">
        <table class="table" style="width: 100%">
          <colgroup>
            <col span="2" />
            <col :class="{ 'collapsed-border': columnsCollapsed.budget }" />
            <col :span="sheet && sheet.withVariation ? 5 : 3" />
            <col :class="{ 'collapsed-border': columnsCollapsed.isf }" />
            <col :span="columnsCollapsed.isf ? 5 : 6" />
            <col :class="{ 'collapsed-border': columnsCollapsed.breakdown }" />
            <col :span="columnsCollapsed.breakdown ? 2 : 3" />
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2" class="has-background-white">Catégorie</th>
              <th rowspan="2" class="has-background-white">Prestation</th>
              <th rowspan="2" class="has-background-white">Budget Référence</th>
              <th colspan="2" class="has-background-white">Budget</th>
              <th
                colspan="2"
                class="has-background-light"
                v-if="!sheet || !sheet.withVariation"
              >
                Dépenses
              </th>
              <template v-else>
                <th rowspan="2" class="has-background-white">
                  Dépenses au stade
                </th>
                <th rowspan="2" class="has-background-light">Ecart cumulé</th>
                <th rowspan="2" class="has-background-white">
                  Dépenses du mois
                </th>
                <th rowspan="2" class="has-background-light">Ecarts du mois</th>
              </template>
              <th
                colspan="2"
                class="has-background-white"
                :class="{ collapsed: columnsCollapsed.isf }"
              >
                <div class="is-relative">
                  ISF
                  <div
                    class="button is-small is-light collapse-button"
                    @click="columnsCollapsed.isf = !columnsCollapsed.isf"
                    title="ISF"
                  >
                    <font-awesome-icon
                      icon="minus"
                      v-if="!columnsCollapsed.isf"
                    />
                    <font-awesome-icon icon="plus" v-else />
                  </div>
                </div>
              </th>
              <th rowspan="2" class="has-background-light">CAP/FNP</th>
              <th rowspan="2" class="has-background-white">RAD</th>
              <th rowspan="2" class="has-background-light">Dépenses FDC</th>
              <th colspan="2" class="has-background-white">Dépenses FDC vs</th>
              <th
                rowspan="2"
                class="has-background-light"
                :class="{ collapsed: columnsCollapsed.breakdown }"
              >
                <div class="is-relative">
                  % budget tâche / budget total
                  <div
                    class="button is-small is-light collapse-button"
                    @click="
                      columnsCollapsed.breakdown = !columnsCollapsed.breakdown
                    "
                    title="% budget tâche / budget total"
                  >
                    <font-awesome-icon
                      icon="minus"
                      v-if="!columnsCollapsed.breakdown"
                    />
                    <font-awesome-icon icon="plus" v-else />
                  </div>
                </div>
              </th>
              <th
                :colspan="sheet && sheet.withVariation ? 2 : 3"
                class="has-background-white"
              >
                Avancement
              </th>
              <!-- <th colspan="3" class="has-background-light">OSCAR</th> -->
            </tr>
            <tr>
              <th class="has-background-white inline-border">APS</th>
              <th class="has-background-white inline-border">EXE</th>
              <template v-if="!sheet || !sheet.withVariation">
                <th class="has-background-light inline-border">Au stade</th>
                <th class="has-background-light inline-border">Du mois</th>
              </template>
              <th
                class="has-background-white inline-border"
                :class="{ collapsed: columnsCollapsed.isf }"
              >
                Commandé
              </th>
              <th
                class="has-background-white inline-border"
                v-if="!columnsCollapsed.isf"
              >
                Facturé
              </th>
              <th class="has-background-white inline-border">FDC M-1</th>
              <th class="has-background-white inline-border">
                Budget de référence
              </th>
              <th class="collapsed" v-if="columnsCollapsed.breakdown"></th>
              <!-- <th>Budget initial CDP</th> -->
              <th class="has-background-white inline-border">Financier</th>
              <th class="has-background-white inline-border">Physique</th>
              <th
                class="has-background-white inline-border"
                v-if="sheet && sheet.withVariation"
              >
                Du mois
              </th>
              <!-- <th class="has-background-light">Date début de tâche</th>
              <th class="has-background-light">Date fin de tâche</th>
              <th class="has-background-light">Avancement</th> -->
            </tr>
          </thead>
          <tbody v-if="sheet">
            <template v-for="(category, catIndex) in sheet.categories">
              <tr :key="catIndex">
                <td class="is-info sub-total" colspan="2">
                  {{ category.categoryName }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-referenceBudget' + category.categoryId"
                >
                  {{ category.referenceBudgetAmount | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-budgetAps' + category.categoryId"
                >
                  {{ category.amountAps | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-budgetExe' + category.categoryId"
                >
                  {{ category.amountExe | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-phaseExpenses' + category.categoryId"
                >
                  {{ category.phaseExpenses | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-totalVariation' + category.categoryId"
                  v-if="sheet && sheet.withVariation"
                >
                  {{ category.totalVariation | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-monthExpenses' + category.categoryId"
                >
                  {{ category.monthExpenses | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-monthVariation' + category.categoryId"
                  v-if="sheet && sheet.withVariation"
                >
                  {{ category.monthVariation | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :class="{ collapsed: columnsCollapsed.isf }"
                  :cy-data="'cat-isfOsAmount' + category.categoryId"
                >
                  {{ category.isfOsAmount | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  v-if="!columnsCollapsed.isf"
                  :cy-data="'cat-isfInvoiced' + category.categoryId"
                >
                  {{ category.isfInvoiced | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-capFnp' + category.categoryId"
                >
                  {{ category.capFnp | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-rad' + category.categoryId"
                >
                  {{ category.rad | number }}
                </td>
                <td
                  class="is-info sub-total has-text-right"
                  :cy-data="'cat-amount' + category.categoryId"
                >
                  {{ category.budgetAmount | number }}
                </td>
                <td class="is-info sub-total has-text-right">
                  {{
                    (category.budgetAmount - category.previousAmount) | number
                  }}
                </td>
                <td class="is-info sub-total has-text-right">
                  {{
                    (category.budgetAmount - category.referenceBudgetAmount)
                      | number
                  }}
                </td>
                <!-- <td class="is-info sub-total has-text-right">{{ cdpBudget ? category.budgetAmount - category['amount' + cdpBudget] : 0| number }}</td> -->
                <td
                  class="is-info sub-total has-text-centered"
                  :class="{ collapsed: columnsCollapsed.breakdown }"
                >
                  {{
                    sheet.totalBudget === 0
                      ? 0
                      : (category.budgetAmount / sheet.totalBudget) | percentage
                  }}
                </td>
                <td class="is-info sub-total has-text-centered">
                  {{ category.financialProgress | percentage(2) }}
                </td>
                <td
                  class="is-info sub-total has-text-centered"
                  :cy-data="'cat-progress' + category.categoryId"
                >
                  {{ category.progress | percentage(2) }}
                </td>
                <td
                  class="is-info sub-total has-text-centered"
                  :cy-data="'cat-monthProgress' + category.categoryId"
                  v-if="sheet && sheet.withVariation"
                >
                  {{
                    (category.progress - category.previousProgress)
                      | percentage(2)
                  }}
                </td>
              </tr>
              <template v-for="prestation in category.prestations">
                <tr :key="'p-' + prestation.prestationId">
                  <td>{{ prestation.subCategoryName }}</td>
                  <td>{{ prestation.prestationName }}</td>
                  <td
                    class="has-text-right"
                    :cy-data="'referenceBudget' + prestation.prestationId"
                  >
                    {{ prestation.referenceBudgetAmount | number }}
                  </td>
                  <td
                    class="has-text-right"
                    :cy-data="'budgetAps' + prestation.prestationId"
                  >
                    {{ prestation.amountAps | number }}
                  </td>
                  <td
                    class="has-text-right"
                    :cy-data="'budgetExe' + prestation.prestationId"
                  >
                    {{ prestation.amountExe | number }}
                  </td>
                  <td
                    class="has-text-right"
                    :class="{
                      'has-background-light': !sheet || !sheet.withVariation,
                    }"
                    :cy-data="'phaseExpenses' + prestation.prestationId"
                  >
                    {{ prestation.phaseExpenses | number }}
                  </td>
                  <td
                    class="has-text-right has-background-light"
                    :cy-data="'totalVariation' + prestation.prestationId"
                    v-if="sheet && sheet.withVariation"
                  >
                    {{ prestation.totalVariation | number }}
                  </td>
                  <td
                    class="has-text-right"
                    :class="{
                      'has-background-light': !sheet || !sheet.withVariation,
                    }"
                    :cy-data="'monthExpenses' + prestation.prestationId"
                  >
                    {{ prestation.monthExpenses | number }}
                  </td>
                  <td
                    class="has-text-right has-background-light"
                    :cy-data="'monthVariation' + prestation.prestationId"
                    v-if="sheet && sheet.withVariation"
                  >
                    {{ prestation.monthVariation | number }}
                  </td>
                  <td
                    class="has-text-right"
                    :class="{ collapsed: columnsCollapsed.isf }"
                    :cy-data="'isfOsAmount' + prestation.prestationId"
                  >
                    {{ prestation.isfOsAmount | number }}
                  </td>
                  <td
                    class="has-text-right"
                    v-if="!columnsCollapsed.isf"
                    :cy-data="'isfInvoiced' + prestation.prestationId"
                  >
                    {{ prestation.isfInvoiced | number }}
                  </td>
                  <td
                    class="has-text-right has-background-light"
                    :cy-data="'capFnp' + prestation.prestationId"
                  >
                    {{ prestation.capFnp | number }}
                  </td>
                  <td
                    class="has-text-right"
                    :cy-data="'rad' + prestation.prestationId"
                  >
                    {{ prestation.rad | number }}
                  </td>
                  <td
                    class="has-text-right has-background-light"
                    :cy-data="'amount' + prestation.prestationId"
                  >
                    {{ prestation.amount | number }}
                  </td>
                  <td class="has-text-right">
                    {{
                      (prestation.amount - prestation.previousAmount) | number
                    }}
                  </td>
                  <td class="has-text-right">
                    {{
                      (prestation.amount - prestation.referenceBudgetAmount)
                        | number
                    }}
                  </td>
                  <!-- <td class="has-text-right">{{ cdpBudget ? prestation.amount - prestation['amount' + cdpBudget] : prestation.amount| number }}</td> -->
                  <td
                    class="has-text-centered has-background-light"
                    :class="{ collapsed: columnsCollapsed.breakdown }"
                  >
                    {{
                      sheet.totalBudget == 0
                        ? 0
                        : (prestation.amount / sheet.totalBudget) | percentage
                    }}
                  </td>
                  <td class="has-text-centered">
                    {{
                      prestation.amount == 0
                        ? 0
                        : (prestation.phaseExpenses / prestation.amount)
                          | percentage(2)
                    }}
                  </td>
                  <td
                    class="has-text-centered"
                    :cy-data="'progress' + prestation.prestationId"
                  >
                    {{ prestation.progress | percentage(2) }}
                  </td>
                  <td
                    class="has-text-centered"
                    :cy-data="'monthProgress' + prestation.prestationId"
                    v-if="sheet && sheet.withVariation"
                  >
                    {{
                      (prestation.progress - prestation.previousProgress)
                        | percentage(2)
                    }}
                  </td>
                </tr>
              </template>
            </template>
            <!-- UNAFFECTED -->
            <tr
              v-if="sheet.unaffectedPrestation"
              class="has-background-danger-light"
            >
              <td colspan="2">
                {{ sheet.unaffectedPrestation.subCategoryName }}
              </td>
              <td colspan="3"></td>
              <td class="has-text-right" :cy-data="'totalUnaffectedSpent'">
                {{ sheet.unaffectedPrestation.phaseExpenses | number }}
              </td>
              <td v-if="sheet && sheet.withVariation"></td>
              <td class="has-text-right">
                {{ sheet.unaffectedPrestation.monthExpenses | number }}
              </td>
              <td v-if="sheet && sheet.withVariation"></td>
              <td
                class="has-text-right"
                :class="{ collapsed: columnsCollapsed.isf }"
                :cy-data="'totalUnaffectedOrdered'"
              >
                {{ sheet.unaffectedPrestation.isfOsAmount | number }}
              </td>
              <td
                class="has-text-right"
                v-if="!columnsCollapsed.isf"
                :cy-data="'totalUnaffectedInvoiced'"
              >
                {{ sheet.unaffectedPrestation.isfInvoiced | number }}
              </td>
              <td colspan="2"></td>
              <td class="has-text-right">
                {{ sheet.unaffectedPrestation.phaseExpenses | number }}
              </td>
              <td class="has-text-right">
                {{ sheet.unaffectedPrestation.phaseExpenses | number }}
              </td>
              <td class="has-text-right">
                {{ sheet.unaffectedPrestation.phaseExpenses | number }}
              </td>
              <td
                class="has-text-centered"
                :class="{ collapsed: columnsCollapsed.breakdown }"
              >
                {{
                  sheet.totalBudget == 0
                    ? 0
                    : (sheet.unaffectedPrestation.phaseExpenses /
                        sheet.totalBudget)
                      | percentage
                }}
              </td>
              <td class="has-text-centered">100,00 %</td>
              <td class="has-text-centered">100,00 %</td>
              <td v-if="sheet && sheet.withVariation"></td>
              <!-- <td colspan="3"></td> -->
            </tr>
            <!-- TOTAL -->
            <tr class="total">
              <td class="is-info" colspan="2">Dépenses Affaire</td>
              <td class="is-info has-text-right">
                {{ sheet.totalReferenceBudget | number }}
              </td>
              <td class="is-info has-text-right">
                {{ sheet.totalAmountAps | number }}
              </td>
              <td class="is-info has-text-right">
                {{ sheet.totalAmountExe | number }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'totalPhaseExpenses'"
              >
                {{ sheet.totalPhaseExpenses | number }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'totalVariation'"
                v-if="sheet && sheet.withVariation"
              >
                {{ sheet.totalVariation | number }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'totalMonthExpenses'"
              >
                {{ sheet.totalMonthExpenses | number }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'totalMonthVariation'"
                v-if="sheet && sheet.withVariation"
              >
                {{ sheet.monthVariation | number }}
              </td>
              <td
                class="is-info has-text-right"
                :class="{ collapsed: columnsCollapsed.isf }"
                :cy-data="'totalIsfOsAmount'"
              >
                {{ sheet.totalIsfOsAmount | number }}
              </td>
              <td
                class="is-info has-text-right"
                v-if="!columnsCollapsed.isf"
                :cy-data="'totalIsfInvoiced'"
              >
                {{ sheet.totalIsfInvoiced | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'totalCapFnp'">
                {{ sheet.totalCapFnp | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'totalRad'">
                {{ sheet.totalRad | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'totalBudget'">
                {{ sheet.totalBudget | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'budgetVsM-1'">
                {{ (sheet.totalBudget - sheet.totalPreviousAmount) | number }}
              </td>
              <td class="is-info has-text-right">
                {{ (sheet.totalBudget - sheet.totalReferenceBudget) | number }}
              </td>
              <!-- <td class="is-info has-text-right">{{ cdpBudget ? totalBudget - sheet['totalAmount' + cdpBudget] : 0| number }}</td> -->
              <td
                class="is-info"
                colspan="1"
                :class="{ collapsed: columnsCollapsed.breakdown }"
              ></td>
              <td
                class="is-info has-text-centered"
                :cy-data="'totalFinancialProgress'"
              >
                {{
                  sheet.totalBudget == 0
                    ? 0
                    : (sheet.totalPhaseExpenses / sheet.totalBudget)
                      | percentage(2)
                }}
              </td>
              <td class="is-info has-text-centered" :cy-data="'totalProgress'">
                {{ sheet.totalProgress | percentage(2) }}
              </td>
              <td
                class="is-info has-text-centered"
                :cy-data="'totalMonthProgress'"
                v-if="sheet && sheet.withVariation"
              >
                {{
                  (sheet.totalProgress - sheet.totalPreviousProgress)
                    | percentage(2)
                }}
              </td>
            </tr>
            <tr>
              <td colspan="18"></td>
            </tr>
            <!-- CATEGORY INCOMES -->
            <tr
              class="sub-total"
              v-for="(income, index) in sheet.categories"
              :key="'i-' + index"
            >
              <td class="is-info" colspan="2">
                Recettes {{ income.categoryName }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'referenceBudgetIncomes' + income.categoryId"
              >
                {{ income.referenceBudgetIncomes | number }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'incomesAps' + income.categoryId"
              >
                {{ income.incomeAps | number }}
              </td>
              <td
                class="is-info has-text-right"
                :cy-data="'incomesExe' + income.categoryId"
              >
                {{ income.incomeExe | number }}
              </td>
              <td class="is-info" :colspan="sheet.withVariation ? 4 : 2"></td>
              <td
                class="is-info has-text-right"
                :colspan="columnsCollapsed.isf ? 3 : 4"
              ></td>
              <td
                class="is-info has-text-right"
                :cy-data="'incomes' + income.categoryId"
              >
                {{ income.incomes | number }}
              </td>
              <td class="is-info has-text-right">
                {{ income.totalIncomes | number }}
              </td>
              <td class="is-info" colspan="5"></td>
            </tr>
            <!-- TOTAL INCOMES -->
            <tr class="total">
              <td class="is-info" colspan="2">Recettes Affaire</td>
              <td
                class="is-info has-text-right"
                :cy-data="'totalReferenceBudgetIncomes'"
              >
                {{ sheet.totalReferenceBudgetIncomes | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'totalIncomesAps'">
                {{ sheet.totalIncomesAps | number }}
              </td>
              <td class="is-info has-text-right" :cy-data="'totalIncomesExe'">
                {{ sheet.totalIncomesExe | number }}
              </td>
              <td class="is-info" :colspan="sheet.withVariation ? 4 : 2"></td>
              <td
                class="is-info has-text-right"
                :colspan="columnsCollapsed.isf ? 3 : 4"
              ></td>
              <td class="is-info has-text-right" :cy-data="'totalIncomes'">
                {{ sheet.totalIncomes | number }}
              </td>
              <td class="is-info has-text-right">
                {{ sheet.totalIncomesPreviousMonth | number }}
              </td>
              <td class="is-info" colspan="5"></td>
            </tr>
            <tr>
              <td colspan="18"></td>
            </tr>
            <tr
              class="sub-total"
              v-for="(category, index) in sheet.categories"
              :key="'mb-' + index"
            >
              <td class="is-primary" colspan="2">
                MB {{ category.categoryName }}
              </td>
              <td class="is-primary has-text-right">
                {{
                  (category.referenceBudgetIncomes -
                    category.referenceBudgetAmount)
                    | number
                }}
              </td>
              <td class="is-primary has-text-right">
                {{ (category.incomeAps - category.amountAps) | number }}
              </td>
              <td class="is-primary has-text-right">
                {{ (category.incomeExe - category.amountExe) | number }}
              </td>
              <td
                class="is-primary"
                :colspan="sheet.withVariation ? 4 : 2"
              ></td>
              <td
                class="is-primary has-text-right"
                :colspan="columnsCollapsed.isf ? 3 : 4"
              ></td>
              <td class="is-primary has-text-right">
                {{ (category.incomes - category.budgetAmount) | number }}
              </td>
              <td class="is-primary has-text-right">
                {{ category.totalMargin | number }}
              </td>
              <td class="is-primary" colspan="5"></td>
            </tr>
            <tr class="total">
              <td class="is-primary" colspan="2">MB Affaire</td>
              <td class="is-primary has-text-right">
                {{
                  (sheet.totalReferenceBudgetIncomes -
                    sheet.totalReferenceBudget)
                    | number
                }}
              </td>
              <td class="is-primary has-text-right">
                {{ (sheet.totalIncomesAps - sheet.totalAmountAps) | number }}
              </td>
              <td class="is-primary has-text-right">
                {{ (sheet.totalIncomesExe - sheet.totalAmountExe) | number }}
              </td>
              <td
                class="is-primary"
                :colspan="sheet.withVariation ? 4 : 2"
              ></td>
              <td
                class="is-primary has-text-right"
                :colspan="columnsCollapsed.isf ? 3 : 4"
              ></td>
              <td class="is-primary has-text-right">
                {{ (sheet.totalIncomes - sheet.totalBudget) | number }}
              </td>
              <td class="is-primary has-text-right">
                {{ sheet.previousMbAffaire | number }}
              </td>
              <td class="is-primary" colspan="5"></td>
            </tr>
            <tr class="total">
              <td class="is-primary" colspan="2">% MB Affaire</td>
              <td class="is-primary has-text-right">
                {{
                  sheet.totalReferenceBudgetIncomes !== 0
                    ? 1 -
                      sheet.totalReferenceBudget /
                        sheet.totalReferenceBudgetIncomes
                    : 0 | percentage(2)
                }}
              </td>
              <td class="is-primary has-text-right">
                {{
                  sheet.totalIncomesAps !== 0
                    ? 1 - sheet.totalAmountAps / sheet.totalIncomesAps
                    : 0 | percentage(2)
                }}
              </td>
              <td class="is-primary has-text-right">
                {{
                  sheet.totalIncomesExe !== 0
                    ? 1 - sheet.totalAmountExe / sheet.totalIncomesExe
                    : 0 | percentage(2)
                }}
              </td>
              <td
                class="is-primary"
                :colspan="sheet.withVariation ? 4 : 2"
              ></td>
              <td
                class="is-primary has-text-right"
                :colspan="columnsCollapsed.isf ? 3 : 4"
              ></td>
              <td class="is-primary has-text-right">
                {{
                  sheet.totalIncomes !== 0
                    ? 1 - sheet.totalBudget / sheet.totalIncomes
                    : 0 | percentage(2)
                }}
              </td>
              <td class="is-primary has-text-right">
                {{ sheet.previousPercentMbAffaire | percentage(2) }}
              </td>
              <td class="is-primary" colspan="5"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "synthesis-sheets",
  data() {
    return {
      currentMonth: "draft",
      months: [],
      projects: [],
      projectsColumns: {
        name: "Projet",
        "region.name": "Région",
        "region.direction.name": "Direction",
      },
      sheet: null,
      sheetTemplates: [],
      sheets: [],
      statuses: [],
      administrationStatuses: [],
      milestones: [],
      owners: [],
      projectsLength: 0,
      sheetTemplatesLength: 0,
      columnsCollapsed: {
        isf: false,
        breakdown: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch("App/loadLatestRefresh");

    this.$Api.Sheet.fetchPeriods().then((periods) => {
      this.months = [
        {
          value: "draft",
          name: "Aujourd'hui (non historisé)",
        },
      ];

      periods.forEach((p) => {
        const period = moment(p);
        const year = period.format("Y");
        const month = period.format("M");
        const months = this.$Api.Sheet.getMonths();
        let name = months[month] + " " + year;

        this.months.push({
          value: p,
          name,
        });
      });
    });
  },
  computed: {
    ...mapGetters({
      latestRefresh: "App/getLatestRefresh",
    }),
    totalAmountAps() {
      return this.sheet.categories.reduce((acc, val) => {
        acc = acc + val.amountAps;
        return acc;
      }, 0);
    },
    projectsIds() {
      if (this.projects.length === 0) return [];

      return this.projects.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    sheetTemplatesIds() {
      if (this.sheetTemplates.length === 0) return [];

      return this.sheetTemplates.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    sheetsIds() {
      if (this.sheets.length === 0) return [];

      return this.sheets.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    milestoneNames() {
      if (this.milestones.length === 0) return [];

      return this.milestones.reduce((acc, val) => {
        return [...acc, val.milestone];
      }, []);
    },
    statusesIds() {
      if (this.statuses.length === 0) return [];

      return this.statuses.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    administrationStatusesIds() {
      if (this.administrationStatuses.length === 0) return [];

      return this.administrationStatuses.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    ownersIds() {
      if (this.owners.length === 0) return [];

      return this.owners.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
  },
  watch: {
    projects(val) {
      this.updateSheetTemplates();

      if (
        this.projectsLength === 0 ||
        (val.length !== 0 && this.projectsLength > val.length)
      ) {
        this.statuses = [];
        this.administrationStatuses = [];
        this.milestones = [];
        this.owners = [];
      }

      this.projectsLength = val.length;
    },
    sheetTemplates(val) {
      this.updateSheets();

      if (
        this.sheetTemplatesLength === 0 ||
        (val.length !== 0 && this.sheetTemplatesLength > val.length)
      ) {
        this.statuses = [];
        this.administrationStatuses = [];
        this.milestones = [];
        this.owners = [];
      }

      this.sheetTemplatesLength = val.length;
    },
  },
  methods: {
    fetchItems() {
      this.$Api.Sheet.fetchGeneralSheet.bind(
        this,
        this.getParams()
      )((data) => {
        this.sheet = data;
      });
    },
    getParams() {
      return (
        "projects=" +
        this.projectsIds +
        "&sheettemplates=" +
        this.sheetTemplatesIds +
        "&sheets=" +
        this.sheetsIds +
        "&statuses=" +
        this.statusesIds +
        "&administrationStatuses=" +
        this.administrationStatusesIds +
        "&milestones=" +
        this.milestoneNames +
        "&owners=" +
        this.ownersIds +
        "&period=" +
        this.currentMonth
      );
    },
    updateSheetTemplates() {
      for (let i = this.sheetTemplates.length - 1; i >= 0; i--) {
        if (!this.projectsIds.includes(this.sheetTemplates[i].project.id)) {
          this.sheetTemplates.splice(i, 1);
        }
      }
      this.updateSheets();
    },
    updateSheets() {
      if (
        this.sheets.length === 0 ||
        (this.projects.length === 0 && this.sheetTemplates.length === 0)
      )
        return;

      for (let i = this.sheets.length - 1; i >= 0; i--) {
        if (this.projects.length !== 0 && this.sheetTemplates.length === 0) {
          if (!this.projectsIds.includes(this.sheets[i].project.id)) {
            this.sheets.splice(i, 1);
          }
        }

        if (this.sheetTemplates.length !== 0) {
          if (
            !this.sheetTemplatesIds.includes(this.sheets[i].sheetTemplate.id)
          ) {
            this.sheets.splice(i, 1);
          }
        }
      }
    },
    updateMilestones() {
      if (
        this.milestones.length === 0 ||
        (this.projects.length === 0 && this.sheetTemplates.length === 0)
      )
        return;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 20px;
}
thead {
  position: sticky;
  top: 52px;
  z-index: 20;
}
th {
  text-align: center !important;
  border: none;
}
.inline-border {
  box-shadow: 0 -2px #dbdbdb;
}
.filters {
  display: flex;
  padding: 10px;
  margin-bottom: 15px;
}
.filter-item {
  flex: 1;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
  overflow: hidden;
  max-height: 80px;
  transition-duration: 1s;
  transition-property: max-height;
  & > * {
    margin: 0 !important;
  }
  &:hover {
    max-height: 1000px;
    min-height: 110px;
  }
}
.valid-button {
  margin-top: 30px;
}
.sub-total {
  opacity: 0.9;
  td {
    border: none;
  }
}
.total {
  font-weight: bold;
}
.collapsed {
  display: block;
  width: 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
  color: transparent !important;
  white-space: nowrap;
}
.collapse-button {
  position: absolute;
  top: -35px;
  left: 0;
}
.collapsed-border {
  border-right: 2px solid #ccc;
}
</style>
